<template>
<div id="account">
  <div class="account-box">
    <div class="account-left">
      <router-link :to="{path:'/mine'}"><h3><i class="el-icon-arrow-left"></i><span>返回个人中心</span></h3></router-link>
      <p @click="toggle(1)" :class="{active:sub==1}"><span>实名认证</span><i class="el-icon-arrow-right"></i></p>
      <p @click="toggle(2)" :class="{active:sub==2}"><span>我的收藏</span><i class="el-icon-arrow-right"></i></p>
      <p @click="toggle(3)" :class="{active:sub==3}"><span>浏览足迹</span><i class="el-icon-arrow-right"></i></p>
      <p @click="toggle(4)" :class="{active:sub==4}"><span>谁看过我</span><i class="el-icon-arrow-right"></i></p>
      <p @click="toggle(5)" :class="{active:sub==5}"><span>企业解绑</span><i class="el-icon-arrow-right"></i></p>
      <p @click="toggle(6)" :class="{active:sub==6}"><span>邀请面试</span><i class="el-icon-arrow-right"></i></p>
      <p @click="toggle(7)" :class="{active:sub==7}"><span>已收投递</span><i class="el-icon-arrow-right"></i></p>
      <p @click="toggle(8)" :class="{active:sub==8}"><span>企业设置地图</span><i class="el-icon-arrow-right"></i></p>
      <p @click="toggle(9)" :class="{active:sub==9}"><span>企业身份管理</span><i class="el-icon-arrow-right"></i></p>
    </div>
    <div class="account-right">
      <div class="real" v-show="sub==1">
        <div class="account-header">实名认证</div>
        <p>经实名认证后将无法修改，请填写真实的实名信息!</p>
        <el-form ref="form" :model="userModel" label-width="100px">
          <el-form-item label="姓名">
            <el-input :disabled="!userInfo.idCard==''"  v-model="userModel.userName" placeholder="请输入您的姓名"></el-input>
          </el-form-item>
          <el-form-item label="身份证号">
            <el-input :disabled="!userInfo.idCard==''" v-model="userModel.idCard" placeholder="请输入您的身份证号"></el-input>
          </el-form-item>
          <div class="submit-btn" v-show="!userInfo.idCard" @click="submit">提交认证</div>
        </el-form>
      </div>
      <div class="collect"  v-show="sub==2">
         <div class="account-header">我的收藏</div>
         <div class="collect-list" v-if="resumeList.length>0">
            <div class="resume-item" v-for="(item,index) in resumeList" :key="index" @click="jumpResume(item.userId)">
              <div class="avator">
                <img :src="item.headSculpture" />   
              </div>
              <div class="base-info">
                <h3>
                  {{item.userName}}
                 <span>
                  {{item.minimumWage}}<label v-show="item.maximumSalary">-{{item.maximumSalary}}</label>
                 </span>
                </h3>
                <p>
                  <label v-show="item.workExperienceName">{{item.workExperienceName}}<i></i></label>
                  <label v-show="item.educationName">{{item.educationName}}<i></i></label>
                  <label v-show="item.jobStatusName">{{item.jobStatusName}}</label>
                </p>
              </div>
              <div class="base-right">
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
         </div>
         <div class="noData" v-else>
           <img src="@/assets/imgs/personal/icon-tip-empty.png">
           <span>暂无收藏</span>
         </div>
      </div>
      <div class="foot"  v-show="sub==3">
         <div class="account-header">浏览足迹</div>
         <div class="collect-list" v-if="browsingList.length>0">
           <div class="resume-item" v-for="(item,index) in browsingList" :key="index" @click="jumpResume(item.userId)">
              <div class="avator">
                <img :src="item.headSculpture" />   
              </div>
              <div class="base-info">
                <h3>
                  {{item.userName}}
                <span v-show="item.minimumWage">
                  {{item.minimumWage}}<label v-show="item.maximumSalary">-{{item.maximumSalary}}</label>
                 </span>
                </h3>
                <p>
                  <label v-show="item.workExperienceName">{{item.workExperienceName}}<i></i></label>
                  <label v-show="item.educationName">{{item.educationName}}<i></i></label>
                  <label v-show="item.jobStatusName">{{item.jobStatusName}}</label>
                </p>
              </div>
              <div class="base-right">
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="pagination">
              <el-pagination
                @size-change="browsSize"
                @current-change="browsCurrentChange"
                :current-page="browsParams.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="browsParams.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="browsParams.total">
              </el-pagination>
            </div>
         </div>
         <div class="noData" v-else>
           <img src="@/assets/imgs/personal/icon-tip-empty.png">
           <span>暂无浏览足迹</span>
         </div>
      </div>
      <div class="foot" v-show="sub==4">
         <div class="account-header">谁看过我</div>
         <div class="collect-list" v-if="seenList.length>0">
           <div class="resume-item" v-for="(item,index) in seenList" :key="index" @click="jumpResume(item.userId)">
              <div class="avator">
                <img :src="item.headSculpture" />   
              </div>
              <div class="base-info">
                <h3>
                  {{item.userName}}
                 <span>
                  {{item.minimumWage}}<label v-show="item.maximumSalary">-{{item.maximumSalary}}</label>
                 </span>
                </h3>
                <p>
                  <label v-show="item.workExperienceName">{{item.workExperienceName}}<i></i></label>
                  <label v-show="item.educationName">{{item.educationName}}<i></i></label>
                  <label v-show="item.jobStatusName">{{item.jobStatusName}}</label>
                </p>
              </div>
              <div class="base-right">
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="pagination">
              <el-pagination
                @size-change="seenSize"
                @current-change="seenCurrentChange"
                :current-page="seenParams.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="seenParams.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="seenParams.total">
              </el-pagination>
            </div>
         </div>
         <div class="noData" v-else>
           <img src="@/assets/imgs/personal/icon-tip-empty.png">
           <span>暂无数据</span>
         </div>
      </div>
      <div class="unbind" v-show="sub==5">
         <div class="account-header">企业解绑</div>
          <p>企业名称：{{approvalInfo.enterpriseName}}</p>
          <div class="unbindBtn" @click="unbind">解除绑定</div>
      </div>
      <div class="follow" v-show="sub==6">
        <div class="account-header">邀请面试</div>
        <div class="follow-list" v-if="interviewList.length>0">
          <div class="follow-item" v-for="(item,index) in interviewList" :key="index" @click="openInterView(item)">
            <img :src="item.inviteeHeadSculpture" >
            <p>
              <span>
                {{item.inviteeName}}
              </span>
              <label>{{item.positionInfoName}}
               <strong></strong>
              </label>
              <span>
                {{item.minimumWage}}<label v-show="item.maximumSalary">-{{item.maximumSalary}}</label>
              </span>
            </p>
            <i class="el-icon-arrow-right"></i>
            <h4>
                <em v-show="item.exchangeStatus==0" style="color:#999">未接受</em>
                <em v-show="item.exchangeStatus==1" style="color:#01a7a7">已接受</em>
                <em v-show="item.exchangeStatus==2" style="color:#999">拒绝</em>
                <em v-show="item.exchangeStatus==3" style="color:#999">取消</em>
                <em v-show="item.exchangeStatus==4" style="color:#999">超时取消</em>
                <em v-show="item.exchangeStatus==5" style="color:#01a7a7">完成</em>
                <span>面试时间：{{item.interviewTime}}</span>
            </h4>
            
          </div>
        </div>
        <div class="noData" v-else>
          <img src="@/assets/imgs/personal/icon-tip-empty.png">
          <span>暂无面试通知</span>
        </div>
      </div>
      <div class="follow" v-show="sub==7">
        <div class="account-header">已收投递</div>
        <div class="follow-list" v-if="interResumeList.length>0">
          <div class="follow-item" v-for="(item,index) in interResumeList" :key="index" @click="jumpResume(item.dselivererId)">
            <img :src="item.headSculpture" >
             <div class="resume-info">
                <p>
                  <span>{{item.userName}}</span><strong></strong>
                  <span>{{item.workFunctionName}}</span><strong></strong>
                  <span>{{item.educationName}}</span><strong></strong>
                  <span>{{item.workExperienceName}}</span>
                </p>
                <h5>
                  <span>{{item.resumeName}}</span>
                  <label @click.stop="downResume(item)">[ 附件简历下载 ]</label>
                </h5>
             </div>    
              <div class="base-right">
                <i class="el-icon-arrow-right"></i>
              </div>   
          </div>
        </div>
        <div class="noData" v-else>
          <img src="@/assets/imgs/personal/icon-tip-empty.png">
          <span>暂无面试通知</span>
        </div>
      </div>
      <div class="real" v-show="sub==8">
        <div class="account-header">企业设置地图</div>
        <div class="companyMap">
          <el-amap-search-box  class="search-box"  :search-option="searchOption"  :on-search-result="onSearchResult">
          </el-amap-search-box>

          <el-amap ref="map"  vid="amapDemo" :events="events" zoom="18" :center="center"  class="amap-demo">
            <el-amap-marker key="100" :position="center"></el-amap-marker>
          </el-amap>
          <div class="map-btn" @click="setMap">设置企业地图</div>
        </div>
      </div>
      <div class="manage"  v-show="sub==9">
         <div class="account-header">企业身份管理</div>
          <div class="manageList" v-show="attestationStatus==2">
            <el-table
              :data="manageList"
              style="width: 100%">
              <el-table-column
                prop="userName"
                label="姓名"
                width="120">
              </el-table-column>
              <el-table-column
                prop="position"
                label="职位"
                >
              </el-table-column>
              <el-table-column
                prop="mailbox"
                label="接收简历邮箱"
              >
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="入住时间"
                width="180">
              </el-table-column>
              <el-table-column
                fixed="right"
                label="操作"
                width="100"
              >
                <template slot-scope="scope">
                  <el-button
                    @click.native.prevent="unbindManage(scope.row)"
                    type="text"
                    size="small">
                    解除身份绑定
                  </el-button>
                </template>
              </el-table-column>
             </el-table>
          </div>
          <div class="authenty" v-show="!showAuth&&(attestationStatus==0||attestationStatus==3)">
             <h3>企业管理认证后有哪些好处？</h3>
             <div class="authGood">
                <p>1.查看公司现有人员信息</p>
                <img src="@/assets/imgs/company/authOne.png" />
                <p>2.无需联系客服即可移出公司内的非在职人员</p>
                 <img src="@/assets/imgs/company/authTwo.png" />
             </div>
             <div class="authBtn" @click="quickAuth">立即认证</div>
          </div>
          <div class="authCt" v-show="showAuth&&(attestationStatus==0||attestationStatus==3)">
             <el-upload
              class="uploadAuth"
              :action="baseUrl"
              :limit="1"
              :on-success="successAuth"
              >
              <el-button type="primary" class="uploadBtn"  round>上传授权书</el-button>
            </el-upload>
             <el-button type="primary" class="downBtn" @click="downTemp" round>下载模板</el-button>
          </div>
          <div class="attestation" v-show="attestationStatus==1">
             <div class="attestInfo">
              <h3>认证处理中</h3>
              <p>您的企业身份管理已经提交，我们将在一个工作日内尽快审核审核时间: 8:00-22:00</p>
              <div class="attest-ct">
                <div class="info">
                  <img src="@/assets/imgs/personal/auth.png" alt="">
                  <span>证明报告</span> <label>审核中</label>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  <!-- 面试详情 -->
  <div class="interviewDetail">
     <el-dialog
      title="面试通知详情"
      :visible.sync="interviewDialog"
      width="500px"
      >
        <el-form  label-width="110px" class="demo-ruleForm">
          <el-form-item label="职位:" >
            <label>{{interviewInfo.positionInfoName}} · </label>
            <span v-show="interviewInfo.minimumWage=='面议'">面议</span>
            <span v-show="interviewInfo.minimumWage!='面议'">
              {{interviewInfo.minimumWage/1000}}-{{interviewInfo.maximumSalary/1000}}K/月
            </span>
          </el-form-item>
          <el-form-item label="面试状态:" >
            <label v-show="interviewInfo.exchangeStatus==0" style="color:#999">未接受</label>
            <label v-show="interviewInfo.exchangeStatus==1" style="color:#01a7a7">接受</label>
            <label v-show="interviewInfo.exchangeStatus==2" style="color:#999">拒绝</label>
            <label v-show="interviewInfo.exchangeStatus==3" style="color:#999">取消</label>
            <label v-show="interviewInfo.exchangeStatus==4" style="color:#999">超时取消</label>
            <label v-show="interviewInfo.exchangeStatus==5" style="color:#01a7a7">完成</label>
          </el-form-item>
          <el-form-item label="面试时间:" >
             {{ interviewInfo.interviewTime }}
          </el-form-item>
          <el-form-item label="面试地址:" >
             {{ interviewInfo.interviewLocation }}{{ interviewInfo.houseNumber }}
          </el-form-item>
          <el-form-item label="联系人:" >
             {{ interviewInfo.contactsName }}
          </el-form-item>
          <el-form-item label="手机号:" >
             {{ interviewInfo.contactsPhone }}
          </el-form-item>
          <el-form-item label="备注:" >
             {{ interviewInfo.remark }}
          </el-form-item>
        </el-form>
     </el-dialog>
  </div>
  <resume-detail :resumeDialog="resumeDialog" :userId="userId" @closeDialog="closeDialog"></resume-detail>
</div>
</template>

<script>
import {uploadEnterpriseCertificationReport,getAuthenticationStatus,delAttestation,getColleagueManage,getWhoHasSeenMyData,getRecruiterEnterpriseDetails,setUpMap,getEnterpriseReceivingRecords,getInterviewInfoList,getInterviewInfoInfo, certification,unbindTheEnterprise, getUserInfo,viewPersonalApprovalStatus, verifyRealNameAuth,getFavoriteUsersList,getUserBrowsingInfo } from "@/api/index.js";
import resumeDetail from '../resume/resumeDetail.vue'
export default {
  name: "account",
  components: {
    'resume-detail': resumeDetail,
  },
  data() {
    let _this = this;
    return {
      sub: 1,
      userModel: {
        userName: '',
        idCard: ''
      },
      userInfo:{},
      resumeList:[],
      userId: '',
      resumeDialog: false,
      browsingList:[],
      browsParams:{
        page:1,
        size:10,
        total:0
      },
      approvalInfo:{},
      interviewList:[],
      interviewDialog:false,
      interviewInfo:{},
      interResumeList:[],
      seenParams:{
        page:1,
        size:10,
        total:0
      },
      seenList:[],
      center: [93.51452,42.82699],
      events: {
        init(){
          _this.getRecruiterEnterpriseDetails()
        },
        click(e) {
           _this.center = [e.lnglat.lng, e.lnglat.lat];
        }
      },
      searchOption: {
        // 限制搜索城市的范围
        citylimit: false,
      },
      bindCompany:{},
      attestationStatus:'',
      manageList:[],
      showAuth:false,
      baseUrl:''
    }
  },
  mounted(){
     this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
     this.userModel.userName =  this.userInfo.authenticationName
     this.userModel.idCard =  this.userInfo.idCard
     if(this.$route.query.type){
       this.sub = this.$route.query.type
     }
     this.getFavoriteUsersList()
     this.getUserBrowsingInfo()
     this.viewPersonalApprovalStatus()
     this.getInterviewInfoList()
     this.getEnterpriseReceivingRecords()
     this.getWhoHasSeenMyData()
     this.baseUrl = process.env.VUE_APP_BASE_API + '/webApi/common/upload'
  },
  methods: {
    successAuth(res){
       uploadEnterpriseCertificationReport({
         attestationReportUrl:res.url
       }).then(res=>{
         if(res.code==200){
          this.$message({
            message: '上传企业认证管理报告成功',
            type: 'success'
          })
           this.getAuthenticationStatus()
         }
       })
    },
    downTemp(){
      window.open(process.env.VUE_APP_BASE_API + '/web/api/attestationManage/getEnterpriseCertificationReport?enterpriseId='+this.bindCompany.enterpriseId)
    },
    quickAuth(){
      this.showAuth= true
    },
    unbindManage(row){
      this.$confirm('确定要解除同事绑定关系吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
          delAttestation({
            enterpriseId: row.enterpriseId,
            userId: row.userId
          }).then(res=>{
            if(res.code==200){
              this.$message({
                message: '解除同事绑定关系成功！',
                type: 'success'
              })
              this.getColleagueManage()
            }else{
              this.$message({
                message: res.msg,
                type: 'warning'
              })
            }
          })
      })
    },
    getRecruiterEnterpriseDetails(){
       getRecruiterEnterpriseDetails().then(res=>{
          this.bindCompany = res.data
          this.getAuthenticationStatus()
          this.center= [this.bindCompany.enterpriseMapLongitude ||  93.51452 , this.bindCompany.enterpriseMapLatitude||42.82699];
        })
    },
     getAuthenticationStatus(){
        getAuthenticationStatus({
          enterpriseId: this.bindCompany.enterpriseId
        }).then(res=>{
          this.attestationStatus  = res.data.attestationStatus
          if( this.attestationStatus==2){
            this.getColleagueManage()
          }
        })
    },
    getColleagueManage(){
      getColleagueManage({
        enterpriseId: this.bindCompany.enterpriseId
      }).then((res)=>{
        this.manageList = res.rows
      })
    },
    setMap(){
     setUpMap({
        enterpriseMapLatitude: this.center[1],
        enterpriseMapLongitude: this.center[0],
        enterpriseId:this.bindCompany.enterpriseId
     }).then(res=>{
       if(res.code==200){
          this.$message({
            message: '设置成功',
            type: 'success'
          })
       }else{
          this.$message({
            message: res.msg,
            type: 'warning'
          })
       }
     })
    },
    onSearchResult(pois) {
      if (pois && pois.length > 0) {
        //如果长度为1则无需转化
        let poi = pois[0];
        let lng = poi["lng"];
        let lat = poi["lat"];
        this.center = [lng, lat];
      }
    },
    getWhoHasSeenMyData(){
      getWhoHasSeenMyData({
         page:this.seenParams.page,
         size:this.seenParams.size
      }).then(res=>{
        this.seenList = res.rows
        this.seenParams.total = res.total
      })
    },
    seenSize(val){
      this.seenParams.size= val
      this.getWhoHasSeenMyData()
    },
    seenCurrentChange(val){
      this.seenParams.page= val
      this.getWhoHasSeenMyData()
    },
    downResume(item){
      window.location.href = item.attachmentResumeUrl
    },
    getEnterpriseReceivingRecords(){
      getEnterpriseReceivingRecords({
         page:1,
         size:100
      }).then(res=>{
         this.interResumeList = res.rows
      })
    },
    openInterView(item){
      this.interviewDialog = true
      getInterviewInfoInfo({
        id:item.id
      }).then(res=>{
        this.interviewInfo = res.data
      })
    },
    getInterviewInfoList(){
      getInterviewInfoList({
        page:1,
        size:100
      }).then(res=>{
         this.interviewList = res.rows
      })
    },
    unbind(){
       unbindTheEnterprise().then(res=>{
         if(res.code==200){
           this.$message({
            message: '解绑成功！',
            type: 'success'
          })
          this.$router.push({
            path:'/addContacts'
          })
         }
       })
    },
    viewPersonalApprovalStatus(){
      viewPersonalApprovalStatus().then(res=>{
        if(res.data){
           this.approvalInfo=res.data
        }
        
      })
    },
    getUserBrowsingInfo(){
      getUserBrowsingInfo({
        page:this.browsParams.page,
        size:this.browsParams.size
      }).then(res=>{
        this.browsingList = res.rows
        this.browsParams.total = res.total
      })
    },
    browsSize(value){
      this.browsParams.size= value
      this.getUserBrowsingInfo()
    },
    browsCurrentChange(value){
      this.browsParams.page= value
      this.getUserBrowsingInfo()
    },
    getFavoriteUsersList(){
      getFavoriteUsersList({
        page:1,
        size:100
      }).then(res=>{
        this.resumeList = res.rows
      })
    },
    closeDialog() {
      this.resumeDialog = false
    },
    jumpResume(userId){
       this.resumeDialog = true
       this.userId = userId
    },
    toggle(sub) {
      this.sub = sub
    },
    submit() {
      verifyRealNameAuth({
        idCard: this.userModel.idCard,
        userName: this.userModel.userName
      }).then(res => {
        if (res.bindingInfo) {
          this.$confirm('此身份证信息已被，请核实信息的准确性，如无误继续认证将接触旧账号的绑定，并将旧账号的认证驳回，请确认是否继续认证！?', '身份已被绑定！', {
          confirmButtonText: '继续认证',
          cancelButtonText: '修改信息',
          type: 'warning'
        }).then(() => {
          this.certification()
        })
        }else{
          this.certification()
        }
      })
    },
    certification() {
      certification({
        idCard: this.userModel.idCard,
        userName: this.userModel.userName
      }).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '实名认证成功！',
            type: 'success'
          })
          this.getUserInfo()
        }
      })
    },
    getUserInfo() {
      getUserInfo().then(res => {
        this.userInfo = res.data
        localStorage.setItem('userInfo', JSON.stringify(res.data))
      })
    },
  }
};
</script>

<style lang="scss" scoped>
#account {
  min-height:600px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;
  padding-bottom: 20px;
}
.attestInfo {
  width: 665px;
  min-height: 253px;
  margin: 4px auto;
  background: #FFFFFF;
  padding-top: 30px;
  border-radius: 1px 10px 10px 10px;
  overflow: hidden;
  padding-bottom: 40px;

  h3 {
    font-size: 24px;
    font-weight: bold;
    color: #222222;
    text-align: center;
    margin-bottom: 30px;
  }

  p {
    font-size: 16px;
    color: #222222;
    text-align: center;
    margin-bottom: 30px;
  }

  .attest-ct {
    width: 600px;
    height: 140px;
    background: #F5F5F5;
    border-radius: 5px;
    margin: 0 auto;
    padding: 15px 20px;

    .info {
      width: 600px;
      height: 140px;
      background: #FFFFFF;
      border-radius: 5px;

      img {
        width: 86px;
        height: 86px;
        margin: 29px 40px;
        border-radius: 50%;
        float: left;
      }

      span {
        display: block;
        float: left;
        margin-top: 60px;
        font-size: 16px;
        color: #222222;
      }
       label {
          display: block;
          float: right;
          color: #0077FF;
          display: block;
           margin-top: 60px;
           margin-right: 40px;
          float: right;
        }
    }
  }
}
.manage{
  padding-bottom: 20px;
  .manage-tips{
    margin: 20px;
    color: #0077FF;
    font-size: 15px;
  }
  .manageList{
    margin:0 20px;
  }
  .authenty{
    margin:20px;
    p{
      margin: 20px 0;
      font-size: 15px;
      color: #444;
    }
    img{
      width:700px;
    }
  }
  .authBtn{
    width: 300px;
    height: 38px;
    text-align: center;
    line-height: 38px;
    color: #fff;
    font-size: 16px;
    color: #FFFFFF;
    background: #0077FF;
    border-radius: 5px;
    margin: 20px auto;
    cursor: pointer;
  }
  .authCt{
    margin-left: 20px;
    margin-top: 20px;
    position: relative;
  }
}
.companyMap{
  height: 560px;
  padding:15px;
  overflow: hidden;
  .amap-demo{
    height: 450px;
  }
  .el-vue-search-box-container {
   width: 100%;
   margin-left: 0;
   margin-bottom: 10px;
   border-top: 1px solid #eee;
  }
}
.uploadAuth{
  width: 500px;
}
.downBtn{
  position:absolute;
  top: 0 ;
  left: 140px;
  background: #0077FF;
}
.uploadBtn{
  background: #DCE8F6;
  color:#0077FF ;
}
.noData{
  text-align: center;
  img{
    width: 200px;
    height: 120px;
    margin-top: 150px;
  }
  span{
    display: block;
    margin-top: 8px;
    font-size: 13px;
    color: #999;
  }
}
.account-box {
  width: 1200px;
  margin: 0 auto;
  padding-top: 24px;
}

.account-left {
  width: 405px;
  min-height: 200px;
  background: #FFFFFF;
  float: left;
  padding-bottom: 3px;

  h3 {
    height: 54px;
    line-height: 54px;
    font-size: 18px;
    font-weight: 400;
    color: #666666;
    border-bottom: 1px solid #ddd;
    margin: 0 20px;

    span {
      display: inline-block;
      margin-left: 5px;
    }
  }

  p {
    height: 54px;
    line-height: 54px;
    font-size: 16px;
    padding-left: 20px;
    cursor: pointer;
    color: #222222;

    span {
      display: inline-block;
      width: 350px;
    }
  }

  .active {
    background: #0077FF;
    color: #fff;
  }
}

.account-right {
  float: left;
  width: 785px;
  min-height:543px;
  background: #FFFFFF;
  margin-left: 10px;

  .account-header {
    height:54px;
    line-height: 54px;
    border-bottom: 1px solid #ddd;
    margin: 0 20px;
    font-size: 18px;
    font-weight: 500;
    color: #222222;
  }

  .real {
    p {
      font-size: 16px;
      color: #999999;
      margin: 30px 20px 14px;
    }
  }
}
.unbind{
  p{
    margin: 30px;
    font-size: 16px;
    color: #999;
  }
}
.submit-btn {
    width: 100px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    color: #fff;
    font-size: 16px;
    color: #FFFFFF;
    background: #0077FF;
    border-radius: 5px;
    margin-top: 10px;
    margin-left: 100px;
    cursor: pointer;
}
.map-btn{
    width:200px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 16px;
    color: #FFFFFF;
    background: #0077FF;
    border-radius: 5px;
    margin-left: 10px;
    margin-top: 15px;
    cursor: pointer;
}
.unbindBtn{
    width:100px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    color: #fff;
    font-size: 16px;
    color: #FFFFFF;
    background: #0077FF;
    border-radius: 5px;
    margin-top: 30px;
    cursor: pointer;
    margin-left: 30px;
}
.resume-item {
  background: #FFFFFF;
  cursor: pointer;
  border-radius: 8px;
  margin: 15px 25px;
  min-height: 66px;
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid #eee;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;

  .avator {
    float: left;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    span {
      display: block;
      width: 60px;
      height: 20px;
      background: #FA6A43;
      border-radius: 10px;
      font-size: 13px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 20px;
      text-align: center;
      padding: 0 2px;
      margin-left: -5px;
    }
  }

  .base-info {
    float: left;
    min-width: 330px;
    margin-left: 20px;
    h3{
      font-size: 15px;
      span{
        display: inline-block;
        min-width: 60px;
        height: 22px;
        background: #FA6A43;
        border-radius: 10px;
        font-size: 13px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 23px;
        text-align: center;
        padding: 0 6px;
        margin-left: 10px;
      }
    }
    p {
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      margin-top: 8px;
      color: #414a60;

      i {
        display: inline-block;
        width: 1px;
        height: 12px;
        background: #D8DDE3;
        margin: 0 15px;
        position: relative;
        top: 2px;
      }

      .dot {
        width: 4px;
        height: 4px;
        background-color: #BCC4CC;
        border-radius: 50%;
        top: -2px;
      }
    }

    .skill {
      margin-top: 10px;

      span {
        line-height: 22px;
        background: #F0F3F7;
        border-radius: 4px;
        display: inline-block;
        padding: 0 8px;
        font-size: 12px;
        font-weight: 400;
        color: #363F4D;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
  .base-right{
    float: right;
    i{
      font-size: 25px;
      color: #999;
      margin-top: 20px;
    }
  }
}
.pagination{
  margin: 10px;
}
.follow-item{
  min-height: 76px;
  margin: 10px 25px 0;
  background: #FFFFFF;
  border-radius: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  img{
    width: 61px;
    height: 61px;
    float: left;
    border-radius: 6px;
    margin-top: 3px;
  }
  p{
    float: left;
    margin-left: 20px;
    font-size: 14px;
    color: #999999;
    margin-top: 6px;
    margin-bottom: 10px;
    strong{
      display: inline-block;
      width: 1px;
      height: 12px;
      background: #D8DDE3;
      margin: 0 15px;
      position: relative;
      top: 2px;
    }
  }
  span{
    display: block;
    font-size: 17px;
    color: #222222;
    margin-bottom: 8px;
    margin-top: 3px;
  }
  label{
    font-size: 14px;
    color: #999999;
  }
  h4{
    float: right;
    font-weight: 400;
    margin-right: 30px;
    margin-top: 10px;
    em{
      font-style: normal;
    }
    span{
      font-size: 13px;
      color: #666;
      margin-top:7px;
    }
  }
  i{
    float: right;
    font-size: 25px;
    color: #999;
    margin-top: 20px;
  }
  .resume-info{
    float: left;
    span{
      display: inline-block;
    }
    h5{
      font-weight: 100;
      span{
        margin-bottom: 0;
        margin-left: 20px;
        position: relative;
        bottom: 10px;
        font-size: 15px;
        color: #666;
        margin-right: 20px;
      }
      label{
         position: relative;
        bottom: 10px;
        color: #0077FF;
        cursor: pointer;
      }
    }
  }
}
</style><style lang="scss">
.account-right {
  .real{
     .el-input__inner {
        width: 300px;
        height: 40px;
        background: #F5F5F5;
        border-radius: 5px;
        border: none;
      }

      .el-form-item {
        margin-bottom: 10px;
      }

      .el-form-item__label {
        font-size: 16px;
        color: #222222;
      }
  }
}
.interviewDetail{
  .el-dialog__body{
    padding:5px 20px 20px;
  }
  .el-form-item{
    margin-bottom: 0;
  }
}
</style>
